// BASE STYLES (tailwind)
html {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji
}

hr {
  height: 0;
  color: inherit
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

b,strong {
  font-weight: bolder
}

code,kbd,pre,samp {
  font-family: ui-monospace,SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent: 0;
  border-color: inherit
}

button,input,optgroup,select,textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button,select {
  text-transform: none
}

[type=button],[type=reset],[type=submit],button {
  -webkit-appearance: button;
  appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0
}

legend {
  padding: 0
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  appearance: textfield;
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

blockquote,dd,dl,figure,h1,h2,h3,h4,h5,h6,hr,p,pre {
  margin: 0
}

button {
  background-color: transparent;
  background-image: none
}

fieldset,ol,ul {
  margin: 0;
  padding: 0
}

ol,ul {
  list-style: none
}

html {
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji
}

body {
  font-family: inherit;
  line-height: inherit
}

*,:after,:before {
  box-sizing: border-box;
  border: 0 solid
}

hr {
  border-top-width: 1px
}

img {
  border-style: solid
}

textarea {
  resize: vertical
}

input::-moz-placeholder,textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::placeholder,textarea::placeholder {
  opacity: 1;
  color: #9ca3af
}

[role=button],button {
  cursor: pointer
}

table {
  border-collapse: collapse
}

h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
  font-weight: inherit
}

a {
  text-decoration: inherit
}

button,input,optgroup,select,textarea {
  padding: 0;
  line-height: inherit;
  color: inherit
}

code,kbd,pre,samp {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace
}

audio,canvas,embed,iframe,img,object,svg,video {
  display: block;
}

img,video {
  max-width: 100%;
  height: auto
}

[hidden] {
  display: none
}

*,:after,:before {
  --tw-border-opacity: 1;
  border-color: rgba(229,231,235,var(--tw-border-opacity))
}

.container {
  width: 100%
}

@media (min-width: 768px) {
  .container {
      max-width:768px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.left-0 {
  left: 0
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.m-auto {
  margin: auto
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mt-2 {
  margin-top: .5rem
}

.mt-3 {
  margin-top: .75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mt-16 {
  margin-top: 4rem
}

.mt-20 {
  margin-top: 5rem
}

.mt-24 {
  margin-top: 6rem
}

.mt-32 {
  margin-top: 8rem
}

.mt-48 {
  margin-top: 12rem
}

.mt-64 {
  margin-top: 16rem
}

.mt-auto {
  margin-top: auto
}

.-mt-6 {
  margin-top: -1.5rem
}

.-mt-8 {
  margin-top: -2rem
}

.-mt-10 {
  margin-top: -2.5rem
}

.mr-1 {
  margin-right: .25rem
}

.mr-2 {
  margin-right: .5rem
}

.mr-3 {
  margin-right: .75rem
}

.mr-4 {
  margin-right: 1rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mr-8 {
  margin-right: 2rem
}

.mr-12 {
  margin-right: 3rem
}

.mr-16 {
  margin-right: 4rem
}

.mr-20 {
  margin-right: 5rem
}

.mr-24 {
  margin-right: 6rem
}

.-mr-2 {
  margin-right: -.5rem
}

.mb-1 {
  margin-bottom: .25rem
}

.mb-2 {
  margin-bottom: .5rem
}

.mb-3 {
  margin-bottom: .75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mb-24 {
  margin-bottom: 6rem
}

.mb-32 {
  margin-bottom: 8rem
}

.-mb-24 {
  margin-bottom: -6rem
}

.ml-2 {
  margin-left: .5rem
}

.ml-3 {
  margin-left: .75rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-6 {
  margin-left: 1.5rem
}

.ml-8 {
  margin-left: 2rem
}

.-ml-2 {
  margin-left: -.5rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-1 {
  height: .25rem
}

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-12 {
  height: 3rem
}

.h-full {
  height: 100%
}

.w-0 {
  width: 0
}

.w-8 {
  width: 2rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-auto {
  width: auto
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-3\/4 {
  width: 75%
}

.w-1\/6 {
  width: 16.666667%
}

.w-1\/12 {
  width: 8.333333%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-full {
  width: 100%
}

.flex-1 {
  flex: 1 1 0%
}

.flex-grow {
  flex-grow: 1
}

.table-auto {
  table-layout: auto
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.grid-cols-2 {
  grid-template-columns: repeat(2,minmax(0,1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3,minmax(0,1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4,minmax(0,1fr))
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.content-center {
  align-content: center
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-evenly {
  justify-content: space-evenly
}

.gap-2 {
  grid-gap: .5rem;
  gap: .5rem
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem
}

.gap-16 {
  grid-gap: 4rem;
  gap: 4rem
}

.overflow-hidden {
  overflow: hidden
}

.rounded {
  border-radius: .25rem
}

.rounded-md {
  border-radius: .375rem
}

.rounded-lg {
  border-radius: .5rem
}

.rounded-full {
  border-radius: 9999px
}

.border-2 {
  border-width: 2px
}

.border-4 {
  border-width: 4px
}

.border {
  border-width: 1px
}

.border-dashed {
  border-style: dashed
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229,231,235,var(--tw-border-opacity))
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107,114,128,var(--tw-border-opacity))
}

.border-opacity-75 {
  --tw-border-opacity: 0.75
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229,231,235,var(--tw-bg-opacity))
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156,163,175,var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity))
}

.p-2 {
  padding: .5rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-12 {
  padding: 3rem
}

.p-24 {
  padding: 6rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem
}

.pt-2 {
  padding-top: .5rem
}

.pt-3 {
  padding-top: .75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pt-16 {
  padding-top: 4rem
}

.pt-20 {
  padding-top: 5rem
}

.pr-3 {
  padding-right: .75rem
}

.pr-4 {
  padding-right: 1rem
}

.pb-3 {
  padding-bottom: .75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pb-20 {
  padding-bottom: 5rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pl-3 {
  padding-left: .75rem
}

.pl-6 {
  padding-left: 1.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-middle {
  vertical-align: middle
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-lg {
  font-size: 1.125rem
}

.text-lg,.text-xl {
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.normal-case {
  text-transform: none
}

.italic {
  font-style: italic
}

.leading-tight {
  line-height: 1.25
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107,114,128,var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75,85,99,var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55,65,81,var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31,41,55,var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255,255,255,var(--tw-text-opacity))
}

.underline {
  text-decoration: underline
}

.opacity-0 {
  opacity: 0
}

.opacity-50 {
  opacity: .5
}

.opacity-100 {
  opacity: 1
}

*,:after,:before {
  --tw-shadow: 0 0 #0000
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)
}

.shadow,.shadow-inner {
  box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0,0,0,.06)
}

.focus\:outline-none:focus,.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

*,:after,:before {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur {
  --tw-blur: blur(8px)
}

.transition {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s
}