@use 'sass:color';
@use 'src/styles/variables' as variables;

/* Class names prefixes */
$prefix: "gjs-" !default;
$pfxam: $prefix + "am-";

.editor-container {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  .gjs-four-color,
  .gjs-four-color-h:hover {
    color: variables.$secondary-color;
  }
  .gjs-mdl-container {
    z-index: 1000;
  }

  .gjs-com-badge,
  .gjs-com-badge-red,
  .gjs-badge,
  .gjs-three-bg {
    background-color: variables.$secondary-color;
  }

  .gjs-selected {
    outline-color: variables.$secondary-color;
  }

  .gjs-one-bg {
    background: variables.$sidebar-bg-color;
  }

  #sidebar-left::-webkit-scrollbar,
  #sidebar-left ::-webkit-scrollbar {
    width: 4px;
  }

  #sidebar-left::-webkit-scrollbar-thumb,
  #sidebar-left ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.17);
  }
  .gjs-editor-cont,
  .gjs-editor {
    font-family: "Open Sans" !important;
  }

  #sidebar-left::-webkit-scrollbar-track,
  #sidebar-left ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.024);
  }

  #sidebar-left {
    height: calc(100% - 45px); // based on top toolbar height
    overflow: hidden;
    padding: 0;
    position: relative;
    top: 45px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }

  .gjs-editor-cont,
  .gjs-editor,
  .gjs-sm-sector-label,
  .gjs-block-label .gjs-mdl-container,
  .gjs-sm-label {
    font-family: "Open Sans" !important;
  }

  .gjs-category-title,
  .gjs-layer-title,
  .gjs-block-category .gjs-title,
  .gjs-sm-sector-title {
    letter-spacing: 0.6px !important;
    font-weight: 700 !important;
    font-size: 1.25rem;
  }

  .gjs-sm-sector-title[data-sector-title] {
    padding: 10px;
  }

  .gjs-block-categories .gjs-block-category:last-child {
    border-bottom: none !important;
  }

  .gjs-category-title,
  .gjs-layer-title,
  .gjs-block-category .gjs-title,
  .gjs-sm-sector-title {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 48px;
    padding-inline: 20px;
    width: 100%;
    color: variables.$primary-color;
    cursor: pointer;
  }

  .gjs-sm-sector-title {
    background-color: #fff;
  }

  .gjs-block-category .gjs-title {
    background: transparent;
    border-bottom: none;
    font-size: 1.337rem;
  }

  .gjs-block-category {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  .gjs-block-category.gjs-open {
    padding-bottom: 2rem;
  }
  .gjs-sm-sector-label.component-name {
    margin-left: 1.4rem;
  }

  .gjs-pn-btn.gjs-pn-active {
    background-color: rgba(255, 255, 255, 0.16);
  }

  .gjs-pn-panel.gjs-pn-views {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    width: 22%;
    padding: 0 !important;
    border: 0;
    & .gjs-pn-buttons span {
      padding: 10px 10% 9px 10%;
      width: 100%;
      border-bottom: 0;
      margin: 0 !important;
      border-radius: 0;
      & svg {
        max-width: 22px;
        margin: 0 auto;
        & * {
          stroke: variables.$page-builder-color !important;
        }
      }
      background-color: rgba(0, 0, 0, 0.07);
      transition: background-color ease-out 120ms;
      &.gjs-pn-active {
        background: #fff;
        border-radius: 0;
        box-shadow: none;
        & svg * {
          stroke: variables.$secondary-color !important;
        }
      }
      &:hover {
        background: #fff;
        transition: background-color ease-out 120ms;
        & svg * {
          stroke: variables.$secondary-color !important;
        }
      }
    }
  }

  .pages-panel {
    font-size: 13px;
    background: #ffffff17;
    padding: 5px 18px;
    &:hover .page-popup {
      padding-top: 44px;
      position: absolute;
      top: 0;
      left: 0;
      width: 230px;
      height: 100px;
    }
    &:hover ul {
      opacity: 1;
      visibility: visible;
    }
    & .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 90px;
      max-width: 180px;
      display: inline-flex;
    }
    & ul {
      opacity: 0;
      visibility: hidden;
      z-index: 999;
      color: #fff;
      border-radius: 4px;
      position: absolute;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100vw - 32px);
      max-height: calc(100vh - 32px);
      margin-top: 44px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      top: 0;
      left: 0;
      min-width: 230px;
      & li {
        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
        background-color: #182c3a;
        &.recent {
          color: rgba(255, 255, 255, 0.8);
          line-height: 36px;
          cursor: initial;
        }
        &:hover {
          background-color: #213c50;
        }
        & a {
          font-weight: 700;
          z-index: 9999;
          line-height: 44px;
          display: block;
          &:hover {
            color: inherit;
          }
        }
        &.recent:hover {
          background-color: #182c3a;
        }
      }
      & hr {
        border-color: #13466a;
      }
    }
  }

  .gjs-pn-panels .gjs-pn-commands,
  .gjs-pn-panels .gjs-pn-devices-c,
  .gjs-pn-panels .gjs-pn-options,
  .gjs-pn-panels .gjs-pn-save-btn,
  .editor-column .gjs-pn-panels {
    background: variables.$sidebar-bg-color !important;
    box-shadow: none !important;
  }

  .gjs-pn-panel.gjs-pn-commands {
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    & .gjs-pn-buttons {
      width: 100%;
      justify-content: unset;
      gap: 2.3rem;
    }
  }

  .gjs-toolbar {
    background: #fff;
    color: variables.$page-builder-color;
    border-radius: 0.375rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .gjs-toolbar .gjs-toolbar-item:first-child {
    display: none;
  }

  .gjs-toolbar-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gjs-toolbar svg {
    width: 22px;
  }

  .gjs-toolbar-item {
    width: auto;
    padding: 0.4rem 0.6rem;
    margin: 0.4rem;
    border-radius: 0.275rem;
    &:hover {
      background: #00000017;
    }
  }

  .gjs-pn-views-container {
    padding-top: 39px;
  }

  .gjs-two-color {
    color: #fff;
  }
  .gjs-color-warn {
    color: #e08e00;
    fill: #e08e00;
  }

  #sidebar-left .gjs-two-color {
    color: variables.$page-builder-color;
  }

  .gjs-field,
  .extended-trait .gjs-field-int {
    background-color: #fff;
    color: variables.$page-builder-color;
  }

  .gjs-input-unit,
  input[type="range"] {
    border: none !important;
  }

  .gjs-field-arrow-u,
  .gjs-field-arrow-d {
    border-top-color: variables.$page-builder-color;
    border-bottom-color: variables.$page-builder-color;
  }

  .gjs-field input,
  .gjs-field select,
  .gjs-field textarea,
  #gjs-clm-tags-field,
  .gjs-field.gjs-field-radio {
    border: 1px solid variables.$default-border-color;
    border-radius: 3px;
  }

  .gjs-radio-item-label svg {
    margin: 0 auto;
  }

  .gjs-radio-item input:checked + .gjs-radio-item-label {
    background-color: rgba(0, 0, 0, 0.1);
    color: variables.$primary-color;
    font-weight: 700;
  }
  .gjs-radio-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .gjs-rte-action {
    color: variables.$primary-color;
    font-weight: 700;
    font-size: 1.6rem !important;
    padding: 0 !important;
    min-width: 3.6rem;
    min-height: 2rem;
    margin: 4px;
    margin-left: 0;
    border: 0;
    border-radius: 0.275rem;
    &:first-child {
      margin-left: 4px
    }
    &:hover, &.gjs-rte-active {
      background: #00000017;
    }
  }

  .gjs-rte-action i.fa-bolt {
    color: variables.$secondary-color
  }

  .gjs-rte-action svg, .gjs-rte-action i, .gjs-rte-action b {
    padding: 6px;
    min-width: 36px;
  }

  .extended-trait .gjs-field-int {
    border-radius: 2px;
  }

  .gjs-sm-field.gjs-sm-composite,
  .gjs-sm-composite.gjs-clm-select,
  .gjs-sm-composite.gjs-clm-field {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid variables.$default-border-color;
    border-radius: 3px;
  }

  .gjs-block-label,
  .gjs-sm-header,
  .gjs-mdl-title {
    font-size: 1.35rem;
    font-weight: 700;
  }

  .gjs-editor,
  .gjs-clm-sel-id,
  .gjs-clm-tags,
  .gjs-trt-traits,
  .gjs-layer {
    font-size: 1.25rem;
  }

  .gjs-sm-properties {
    font-size: 1.2rem;
  }

  .gjs-sm-property__padding-top,
  .gjs-sm-property__padding-right,
  .gjs-sm-property__padding-bottom,
  .gjs-sm-property__padding-left,
  .gjs-sm-property__margin-top,
  .gjs-sm-property__margin-right,
  .gjs-sm-property__margin-bottom,
  .gjs-sm-property__margin-left {
    width: 25%;
    padding: 0 1.2px;
    font-size: 11px;
    & select.gjs-input-unit {
      padding-left: 0px;
      padding-right: 4px;
    }
  }

  .gjs-sm-property {
    margin-bottom: 16px;
  }
  .gjs-trt-trait {
    margin-bottom: 10px;
  }

  .fa {
    font-weight: 900 !important;

    &.gjs-block {
      font-size: 32px;
    }
  }

  .fa-repeat:before {
    content: "\f01e" !important;
  }

  .fa-map-o:before {
    content: "\f279";
    font-weight: 400 !important;
  }

  .fa-youtube-play:before {
    content: "\f008";
  }

  .gjs-block-label {
    font-size: 1.35rem;
    font-weight: 700;
  }

  i.gjs-resizer-h.gjs-resizer-h-bc {
    display: none;
  }

  .gjs-resizer-c {
    z-index: 999;
  }

  .gjs-block[title="Share Links"] {
    & .soshare {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 30;
    }
    & .sosharefill {
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 30;
    }
  }

  .gjs-layer.open > .gjs-layer-title-c i.gjs-layer-caret.gjs-layer-open {
    transform: rotate(180deg) !important;
    transition: transform 100ms ease-in-out;
  }

  .gjs-layer .gjs-selected {
    background-color: #eaeaea;
  }

  .gjs-layer-name {
    padding-top: 0.12rem;
    padding-bottom: 0.9rem;
  }

  .gjs-layer-count {
    top: 5px;
    right: 37px;
  }

  .gjs-layer-move {
    right: 15px;
    top: 7px;
    width: 15px;
    padding: 0;
    & > svg {
      width: auto;
    }
  }

  .gjs-badge__icon svg {
    fill: transparent !important;
    stroke: #fff;
    width: 15px;
    height: 14px;
    stroke-width: 2px;
  }

  .gjs-layer__icon svg,
  .gjs-layer-title-c span.gjs-layer__icon {
    display: none;
  }

  .gjs-layer-caret {
    font-size: 1rem;
    top: 0.4rem;
  }

  .gjs-layer-caret {
    top: 2px !important;
  }

  .gjs-trt-trait--range input[type="range"] {
    top: 1.1px;
  }

  .gjs-sm-slider,
  .gjs-field-number {
    .gjs-field-range {
      margin-right: 0.6rem;
    }
    & input[type="range"] {
      top: -1px;
    }
  }

  .gjs-select option,
  .gjs-field-select option,
  .gjs-clm-select option,
  .gjs-sm-select option,
  .gjs-fields option,
  .gjs-sm-unit option {
    background-color: #fff;
    color: variables.$primary-color;
  }

  .gjs-trt-trait.gjs-trt-trait--number .gjs-field-number {
    background: transparent;
  }

  .gjs-trt-trait.gjs-trt-trait--text,
  .gjs-trt-trait.gjs-trt-trait--number,
  .gjs-trt-trait.gjs-trt-trait--select {
    display: block;
    margin-bottom: 6px;
    & .gjs-label-wrp {
      margin-bottom: 3px;
      width: 100%;
      max-width: 100%;
    }
  }

  .gjs-field-number {
    display: flex;
    .gjs-field-int {
      -webkit-box-flex: 1;
      flex: 1 1 65px;
    }
  }

  & button {
    background-color: variables.$secondary-color !important;
    color: #fff !important;
    font-weight: 700;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px 12px !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .gjs-trt-trait.gjs-trt-trait--checkbox {
    & .gjs-label-wrp {
      width: 100%;
      max-width: 100%;
      margin-right: 5px;
    }
  }

  .gjs-field-wrp {
    min-width: 20px;
  }

  .gjs-custom-code .CodeMirror {
    margin-bottom: 20px;
    border-radius: 3px;
  }

  .cm-s-hopscotch .CodeMirror-gutters {
    background: #001c31;
  }

  .cm-s-hopscotch.CodeMirror {
    background: #01121f;
  }

  .gjs-trt-trait__wrp .gjs-label-wrp {
    margin-right: 5px;
  }

  .gjs-trt-trait.gjs-trt-trait--color {
    & .gjs-label-wrp {
      width: 100%;
      max-width: 100%;
    }
    & .gjs-field.gjs-field-color {
      background-color: transparent !important;
      & .gjs-input-holder {
        display: none;
      }
      & .gjs-field-colorp {
        position: static !important;
        display: block;
        width: 28px;
        height: 28px;
      }
      & .gjs-field-colorp .gjs-checker-bg,
      .gjs-field-colorp .gjs-field-colorp-c,
      .gjs-field-color-picker {
        border-radius: 50% !important;
        background-image: none !important;
      }
    }
    & .gjs-field-color-picker {
      box-shadow: none;
    }
  }

  .gjs-field-color .gjs-field-colorp {
    width: 28px;
  }

  .gjs-input-holder .gjs-sm-btn-c button {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .gjs-sm-preview-file {
    border: 1px solid variables.$default-border-color !important;
    border-bottom-width: 0 !important;
  }

  .gjs-sm-property__background-image .gjs-sm-preview-file {
    border-bottom-width: 1px !important;
    & .gjs-sm-preview-file-cnt {
      height: 100px;
    }
    & #gjs-sm-close {
      top: 3px;
    }
  }

  .gjs-mdl-dialog {
    color: variables.$page-builder-color !important;
  }
  .gjs-mdl-header {
    padding: 10px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .gjs-mdl-btn-close {
      position: relative;
      top: unset;
      right: unset;
      line-height: 1;
    }
  }

  .gjs-block {
    align-items: center;
    padding: 1rem;
    color: variables.$page-builder-color;
    &.gjs-one-bg {
      background-color: #fff;
      &:hover {
        background-color: #f1f2f3;
      }
    }
    & .gjs-block__media svg {
      width: 36px !important;
      height: 36px !important;
      max-width: 100%;
      fill: transparent;
      stroke: variables.$page-builder-color;
      & * {
        fill: transparent;
      }
    }

    &.soicons .gjs-block__media svg {
      width: 90px !important;
    }

    &[title="Map"] svg,
    &[title="Video"] svg,
    &[title="Section"] svg,
    &[title="2 Columns"] svg,
    &[title="3 Columns"] svg,
    &[title="2 Columns 3/7"] svg {
      & * {
        fill: variables.$page-builder-color !important;
        stroke-width: 0 !important;
      }
    }
    &[title="Spacer"] svg {
      fill: variables.$page-builder-color;
      stroke: variables.$page-builder-color;
      stroke-width: 10px;
    }

    &[title="Image"] svg {
      stroke-width: 1px;
    }

    &[title="Sidebar"] svg path {
      fill: #3b4148;
    }

    &:hover {
      .gjs-block__media svg * {
        stroke: variables.$secondary-color;
      }
      &[title="Map"] svg *,
      &[title="Video"] svg *,
      &[title="Section"] svg *,
      &[title="2 Columns"] svg *,
      &[title="3 Columns"] svg *,
      &[title="2 Columns 3/7"] svg * {
        fill: variables.$secondary-color !important;
      }
    }

    & .gjs-block__media:has(img) {
      width: calc(100% + 20px);
      & img {
        margin-left: 0px;
        margin-top: -10px;
        width: 100%;
        height: auto;
        aspect-ratio: 1.6;
        background: #ecf7ff;      
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }
    }
  }

  .gjs-field-colorp {
    border: none !important;
    padding: 0 !important;
  }

  .gjs-pn-views .fa-cog,
  span.gjs-pn-btn.fa-download,
  span.gjs-pn-btn.fa-trash {
    display: none;
  }

  .gjs-pn-views .gjs-pn-active {
    pointer-events: none;
  }

  .gjs-pn-btn.fa-arrows-alt:before {
    content: "\f424";
  }

  span.gjs-pn-btn.fa-code {
    display: none;
  }

  span.gjs-pn-btn.url-box {
    display: block;
    padding: 1px 0 1px 40px;
    background: #fff;
    border: 1px solid #011523;
    border-radius: 30px;
    color: #9a9a9a;
    font-size: 1.3rem;
    line-height: 1.8;
    margin: 0 auto;
    cursor: pointer;
    & a {
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      &:hover {
        color: #9a9a9a;
      }
      & span {
        margin-left: 40px;
        margin-right: 15px;
        position: relative;
        top: 0.4px;
      }
    }
  }

  .gjs-trt-trait__wrp.gjs-trt-trait__wrp-id,
  .gjs-trt-trait__wrp.gjs-trt-trait__wrp-title {
    display: none;
  }

  .gjs-pn-panel {
    position: relative;
    padding: 7px 5px;
  }

  .gjs-pn-panel.gjs-pn-views,
  .gjs-pn-panel.gjs-pn-views-container {
    width: 100%;
    z-index: 99;
  }

  .gjs-pn-panel.gjs-pn-views-container {
    padding: 0 0 50px 0;
    display: flex;
    flex-direction: column;
  }

  .gjs-pn-panel.gjs-pn-options {
    right: 0;
  }

  .gjs-pn-panels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .editor-column {
    min-height: 75px;
    flex-grow: 1;
    flex-basis: 100%;
    overflow: auto;
  }

  .editor-clm {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .gjs-cv-canvas {
    width: 100%;
    height: 100%;
    top: 0;
    animation: hideshow 900ms ease-in;
  }

  .gjs-editor-cont > .gjs-editor {
    animation: bgColorChange 900ms ease-out;
  }

  .gjs-blocks-cs {
    border: none;
  }

  .content-tabs {
    background: transparent;
    border-block-end: 1px solid #e6e8ea;
    & .tab {
      text-align: center;
      padding: 1rem 0;
      font-size: 1.2rem;
      cursor: pointer;
      border-block-end: 3px solid transparent;
      letter-spacing: 0.2px;
    }
  }

  .tab-c {
    display: flex;
    justify-content: space-between;
  }

  .tab {
    padding: 10px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: background-color ease-out 120ms;
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
      transition: background-color ease-out 120ms;
      font-weight: 700;
    }

    &.active {
      font-weight: 700;
      border-block-end: 3px solid variables.$secondary-color;
      color: variables.$primary-color;
      &:hover {
        background-color: #fff;
      }
    }
  }

  .gjs-pn-panel.gjs-pn-save-btn {
    padding: 0;
    margin: 0;
  }

  .gjs-btn-save {
    background-color: variables.$secondary-color;
    transition: 125ms background-color ease-in;
    font-size: 1.45rem;
    width: auto;
    font-weight: 700;
    padding: 12px 40px;
    letter-spacing: 0.45px;
    margin: 0;
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 0;
    & i {
      margin-right: 0.7rem;
      font-weight: 300 !important;
      font-size: 1.7rem;
    }
    &:hover {
      background-color: color.adjust(variables.$secondary-color, $lightness: 15%);
    }
  }

  .feather-svg svg * {
    stroke-width: 1.8px !important;
    fill: transparent;
    stroke: #fff !important;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .url-box .feather-svg svg {
    width: 18px;
  }
  .url-box .feather-svg svg * {
    stroke: rgb(154, 154, 154) !important;
  }
  .feather-svg object {
    pointer-events: none;
  }

  .gjs-btn-close {
    margin-right: 0;
    background-color: transparent;
    transition: background-color 125ms ease-in;
    &:hover {
      transition: background-color 125ms ease-in;
      background-color: rgba(255, 255, 255, 0.08);
    }
    & svg {
      max-width: 34px;
      height: auto;
      margin: -6px 0;
      padding: 2px;
    }
  }

  .gjs-one-bg {
    background: #fff;
  }

  .gjs-two-color {
    color: #fff;
  }

  .gjs-three-bg {
    background-color: variables.$secondary-color;
  }

  .gjs-four-color,
  .gjs-four-color-h:hover {
    color: variables.$secondary-color;
  }

  .gjs-mdl-dialog:has(.gjs-asset-manager) {
    max-width: 1200px;
    height: min(700px, 75vh);
    & .gjs-asset-manager {
      display: flex;
      & .gjs-am-file-uploader {
        width: max (25%, 380px);
        max-width: 80%;
      }
      & .gjs-am-assets-cont {
        width: 100%;
      }
    }
  }

  .gjs-am-assets-header {
    padding-left: 0;
    padding-right: 0;
  }

  .gjs-am-add-asset .gjs-am-add-field {
    width: 60%;
  }

  .gjs-am-assets-cont .gjs-am-assets-header:nth-child(2) {
    display: none;
  }

  .gjs-am-file-uploader > form {
    border-color: variables.$default-border-color;
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color ease-out 125ms;
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
      transition: background-color ease-out 125ms;
    }
  }

  .gjs-am-meta {
    display: none;
  }
  .gjs-am-asset {
    border-bottom: none !important;
    padding: 0;
    border-radius: 2px;
  }

  .gjs-am-assets {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
    height: 100%;
    max-height: min(500px, 60vh);
    & .gjs-btn-prim {
      order: 2;
      grid-column: span 6;
      min-width: 50%;
      padding: 0.765rem 15px !important;
    }
    & .gjs-am-close {
      display: none !important;
    }
  }

  .gjs-am-asset.gjs-am-highlight:after {
    content: "\f00c";
    position: absolute;
    top: 1px;
    left: 0px;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    z-index: 99999;
    font-size: 1.2rem;
    color: #fff;
    background: variables.$secondary-color;
    width: 17px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    border-radius: 3px;
  }

  .gjs-am-asset.gjs-am-highlight .gjs-am-preview {
    border-color: variables.$secondary-color;
    border-width: 2px;
  }

  .gjs-am-preview-cont {
    width: 100%;
    min-height: 115px;
    float: none;
    background-color: transparent;
    & .gjs-am-preview {
      background-size: contain;
      border: 1px solid variables.$default-border-color;
    }
  }

  .gjs-am-assets-cont {
    height: auto;
    background: #fff;
    padding-top: 0;
    & form.gjs-am-add-asset {
      display: flex;
      & .gjs-am-add-field {
        float: none;
        width: 100%;
      }
      & button {
        float: none;
        width: auto;
        font-size: 1.3rem;
        padding-left: 1rem;
        min-width: 140px;
        margin-left: 1rem;
      }
    }
  }

  .gjs-am-file-uploader > form #gjs-am-uploadFile {
    cursor: pointer !important;
  }
  .gjs-am-assets-header .gjs-am-add-asset .gjs-btn-prim {
    font-size: 1.2rem;
    padding: 0.765rem 15px !important;
    width: 37%;
  }
}

.clear-am-img {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 11px 20px 11px 8px;
  line-height: 6px;
  width: 26px;
  font-size: 14px;
  text-align: center;
  background: #6f6f6f;
  opacity: 1;
  border-radius: 50%;
  color: #fff;
}

.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input,
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select {
  color: variables.$page-builder-color;
}

##{$prefix}popup {
  width: 260px;
  max-width: 90%;
  height: auto;
  background-color: #fff;
  color: variables.$page-builder-color;
  pointer-events: all;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 12;
  & .gjs-layer-title {
    letter-spacing: 0.6px !important;
    font-weight: 700 !important;
    font-size: 1.25rem;
    padding: 3px 10px 3px 40px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left;
    position: relative;
    cursor: pointer;
    height: 30px;
    padding-inline: unset;
    gap: unset;
  }
  & .gjs-layer-name {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .gjs-layer-count {
    color: variables.$page-builder-color;
    font-size: 11px;
    top: 8px;
  }
  & svg * {
    fill: variables.$page-builder-color;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  & .gjs-layer-vis {
    padding: 3px;
  }
  & .gjs-layer-no-chld > .gjs-layer-title-inn > .gjs-layer-caret {
    display: none;
  }
}

.#{$prefix}popup-body {
  width: auto;
  max-height: min(485px, 65vh);
  min-height: max(20vh, 320px);
  border-radius: 4px;
  overflow-y: scroll;
}

.#{$prefix}popup-heading {
  background-color: #fff;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid variables.$default-border-color;
  font-size: 14px;
  text-align-last: center;
  padding: 7px 12px;
  line-height: 1;
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span {
    color: variables.$page-builder-color;
  }

  & .heading {
    font-weight: 900;
  }

  .close {
    cursor: pointer;
    box-sizing: border-box;
    min-width: 23px;
    & svg * {
      stroke-width: 2px !important;
      fill: transparent;
      stroke: variables.$page-builder-color !important;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}

.w100 {
  width: 100%;
}

.#{$prefix}clm-tags {
  max-height: auto;
  overflow: auto;
}

.gjs-clm-tags #gjs-clm-new {
  color: variables.$page-builder-color;
}

.gjs-clm-tags #gjs-clm-tag-label {
  color: #fff;
}

.#{$pfxam}assets {
  flex-flow: column-reverse;
}

.#{$prefix}zoomin {
  animation: zoomin 0.125s;
}

.#{$prefix}fadein {
  animation: fadein 0.125s;
}

.gjs-editor-cont {
  max-height: calc(100vh - 45px);
  position: relative;
  top: 45px;
}

// INPUT -> RANGE
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  &:focus {
    outline: none;
  }
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: variables.$default-border-color;
  border-radius: 5px;
  height: 4px;
}

input[type="range"]::-moz-range-track {
  background-color: variables.$default-border-color;
  border-radius: 5px;
  height: 4px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -7px;
  border: none;
  height: 16px;
  width: 16px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  margin-top: -7px;
  border: none;
  height: 16px;
  width: 16px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

// SELECT BOX
span.gjs-input-holder:has(select):after,
.gjs-field-select:has(select):after,
#gjs-sm-input-holder:has(> select):after {
  position: absolute;
  top: 2px;
  right: 7px;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f0d7";
  line-height: 1;
  color: variables.$page-builder-color;
  opacity: 0.8;
  font-size: 12px;
}

.gjs-field-select:has(select):after {
  top: 8px !important;
}

.gjs-field .gjs-sel-arrow {
  display: none !important;
}

.gjs-sm-layers.gjs-field {
  &:empty {
    display: none;
  }
  & .gjs-sm-layer {
    border: 1px solid variables.$default-border-color;
    border-radius: 3px;
  }
}

.gjs-sm-stack #gjs-sm-add {
  top: -22px;
  opacity: 0.82;
  padding: 0 !important;
  width: 22px;
  height: 22px;
  & svg {
    stroke: white;
    stroke-width: 0;
    width: 17px;
    height: 16px;
    position: relative;
    right: -2px;
  }
}

span.gjs-input-holder,
.gjs-field-select,
#gjs-sm-input-holder {
  position: relative;
}

#gjs-seoImage span.gjs-input-holder {
  display: block;
}

// CHECKBOXES -> TOGGLES
.gjs-field-wrp.gjs-field-wrp--checkbox {
  position: relative;
  top: -0.5px;
}

.gjs-chk-icon {
  display: block;
  border: 1px solid #b9b9b9;
  top: 1px;
  left: 1px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 #0000, 0 0 #0000;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  position: absolute;
  border: none !important;
  transform: none !important;
  margin: 0 !important;
}

.gjs-field-checkbox {
  padding: 0;
  display: block;
  background-color: variables.$default-border-color !important;
  box-shadow: inset 0 2px 4px #0000000f;
  cursor: pointer;
  border-radius: 9999px;
  width: 36px;
  height: 18px;
  position: relative;
  transition: background-color ease-out 175ms;
}

.gjs-field-checkbox:has(input:checked + .gjs-chk-icon) {
  background-color: variables.$secondary-color !important;
  transition: background-color ease-out 175ms;
  border: none !important;
}

.gjs-field-checkbox input:checked + .gjs-chk-icon {
  left: unset;
  right: 1px !important;
}
// END CHECKBOXES

/* AI POPUP */
.rte-ai-popup {
  background-color: #fff;
  z-index: 9999;
  display: none;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.34);
  border-radius: 5px;
  position: absolute;
  top: -65px;
  left: 0;
  transition: all 2s ease;
  width: 400px;

  .ai-actionbar {
    display: flex;
    .ai-action {
      height: 25px;
      margin: 5px 0 5px 5px;
      padding: 0 5px 0 0;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      &:hover {
        cursor: pointer;
      }
    }

    .ai-action.no-border {
      border-right: none;
    }

    .ai-prompt {
      display: flex;
      align-items: center;
      align-content: center;
      gap: 1rem;
      input {
        padding: 5px 8px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        width: 310px;
      }
      .fa.fa-question,
      .action {
        font-size: 1.3rem;
        padding: 8px;
        background: #0e81d2;
        color: #fff;
        border-radius: 5px;
        letter-spacing: 0.5px;
        &:hover {
          background-color: variables.$secondary-color-dark;
          cursor: pointer;
        }
      }
    }
  }
}

.sp-container {
  margin-left: 5.6rem;
  margin-top: -1rem;
  background-color: #fff !important;
}

.sp-color,
.sp-hue,
.sp-palette .sp-thumb-el,
.sp-palette .sp-thumb-el:hover {
  border: 0 !important;
}

.sp-cf.sp-palette-row.sp-palette-row-selection {
  display: none;
}

button.sp-choose {
  top: 3px;
  position: relative;
  &:hover {
    transform: none !important;
    border: none;
    opacity: 0.9;
  }
}

button.sp-choose {
  text-transform: uppercase;
}

.sp-picker-container,
.sp-input-container {
  z-index: 99;
  & .sp-button-container {
    z-index: -1;
  }
}

.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  border-radius: 2px;
}

.sp-picker-container input.sp-input {
  border: 1px solid variables.$default-border-color;
  border-radius: 3px;
  z-index: 99;
}

.sp-container button {
  background-color: variables.$secondary-color !important;
  color: #fff !important;
  font-weight: 700;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  padding: 6px 12px !important;
  box-shadow: none!important;
  text-shadow: none!important;
}

.ai-popup-wrapper {
  padding: 20px;
}

.gjs-trt-trait__wrp.gjs-trt-trait__wrp-data-display-title {
  display: none;
}

@media screen and (max-width: 1100px) {
  span.gjs-pn-btn.url-box,
  .pages-panel {
    display: none !important;
  }
  .gjs-am-assets {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  form.gjs-am-add-asset {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .editor-container .gjs-am-add-asset .gjs-am-add-field,
  .editor-container .gjs-am-assets-header .gjs-am-add-asset .gjs-btn-prim {
    width: 100% !important;
  }
  .gjs-am-file-uploader > form #gjs-am-uploadFile,
  .gjs-am-file-uploader #gjs-am-title {
    padding: 6rem 3rem;
  }
}

span[title="View components"] {
  display: none;
}

@media screen and (max-width: 767px) {
  .editor-container {
    span[title="View components"],
    span[title="Undo"],
    span[title="Redo"],
    span[title="Knowledge Base"],
    span[title="Toggle Advanced Style Settings"],
    span[title="Open Layer Manager"],
    .gjs-pn-devices-c,
    .gjs-rte-toolbar {
      display: none !important;
    }
    #sidebar-left {
      flex-basis: 220px;
      min-width: 100px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hideshow {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bgColorChange {
  0% {
    background-color: #fff;
  }
  99% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes zoomin {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.repeatable-trait-container {
  padding: 10px 0;
}

.gjs-trt-trait__wrp.gjs-trt-trait__wrp-repeatableImages .gjs-label-wrp {
  display: none;
}



/// AI MODAL

.ai-generator-modal {
  color: #333;
}

.ai-generator-header {
  margin-bottom: 20px;
}

.ai-generator-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #444;
}

.ai-generator-body {
  padding: 10px 0;
}

.ai-generator-options {
  display: flex;
  margin-bottom: 20px;
}

.ai-generator-option {
  margin-right: 20px;
}

.ai-generator-option label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ai-generator-option input {
  margin-right: 8px;
}

.ai-generator-templates h4,
.ai-prompt-container h4,
.ai-preview-container h4 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #444;
}

.ai-templates-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.ai-template-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
}

.ai-template-item:hover {
  background-color: #f5f5f5;
}

.ai-template-item.selected {
  background-color:  #78366a;
  border-color:  #78366a;
  color: white;
}

#ai-prompt-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
}

.ai-preview-container {
  margin-top: 20px;
}

#ai-preview-content {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}

.ai-preview-controls {
  display: flex;
  gap: 10px;
}

.ai-generator-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-loading-indicator {
  display: flex;
  align-items: center;
}

.ai-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color:  #ec5896;
  border-radius: 50%;
  margin-right: 10px;
  animation: ai-spin 1s linear infinite;
}

@keyframes ai-spin {
  to { transform: rotate(360deg); }
}

.ai-btn {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  background-color: white;
  font-size: 14px;
  transition: all 0.3s;
}

.ai-btn:hover {
  border-color:  #ec5896;
  color:  #ec5896;
}

.ai-btn-primary {
  background-color:  #ec5896;
  border-color:  #ec5896;
  color: white;
}

.ai-btn-primary:hover {
  background-color:  #78366a;
  border-color:  #78366a;
  color: white;
}


.ai-generator-category {
  padding: 12px 8px;
  margin: 0px 0px 10px;
  background: linear-gradient(134deg, #c91edc 0%,#c91edc 20%,variables.$secondary-color 80%) !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background 0.2s;
  &:hover {
    transition: background 0.2s;
    background: linear-gradient(144deg, #c91edc 0%,#c91edc 10%,variables.$secondary-color 80%, #c91edc 100%) !important;
  }
}

.ai-gen-inner {
  font-weight: 700;
  color: #fff;
  & svg {
    max-width: 3.4rem;
    height: auto;
    margin-right: 1rem;
    * {
      fill: #fff !important;
    }
  }
}